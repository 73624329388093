var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-row relative"},[_c('div',{staticClass:"h-full flex bg-ide-file-browser-gray",class:{
            'max-w-4/12 flex-1': !_vm.fileExplorerHidden,
            'w-12': _vm.fileExplorerHidden,
        }},[_c('div',{staticClass:"w-12 h-full bg-ide-dock flex flex-col relative z-20"},[_c('div',{staticClass:"w-full"},[_c('button',{staticClass:"flex items-center justify-center transition duration-200 ease-in-out outline-none focus:outline-none text-ide-dock hover:text-white py-4 w-full",attrs:{"aria-label":"Explorer","data-balloon-pos":"right"},on:{"click":() => _vm.onToggleFileExplorer()}},[_c('fileCopySvg',{staticClass:"h-5 fill-current"})],1)]),_c('div',{staticClass:"w-full"},[_c('button',{staticClass:"flex items-center justify-center transition duration-200 ease-in-out outline-none focus:outline-none text-ide-dock hover:text-white py-4 w-full",class:{
                        'cursor-wait': _vm.projectRunning,
                    },attrs:{"aria-label":_vm.projectRunning ? 'Running...' : `${this.projectRunName} lesson`,"data-balloon-pos":"right"},on:{"click":() => !_vm.projectRunning && _vm.onRunProject()}},[(_vm.projectRunning)?_c('AppLoader',{staticClass:"h-4 stroke-current opacity-50"}):_vm._e(),(!_vm.projectRunning)?_c('playSvg',{staticClass:"h-5 fill-current"}):_vm._e()],1)]),(_vm.projectRunning)?_c('div',{staticClass:"w-full"},[_c('button',{staticClass:"flex items-center justify-center transition duration-200 ease-in-out outline-none focus:outline-none text-red-600 hover:text-red-500 py-4 w-full",attrs:{"aria-label":"Stop","data-balloon-pos":"right"},on:{"click":() => _vm.projectRunning && _vm.onStopProject()}},[_c('stopSvg',{staticClass:"h-5 fill-current"})],1)]):_vm._e()]),(_vm.isLoadingTutorialFilesTree)?_c('FileBrowserLoader'):_vm._e(),(!_vm.isLoadingTutorialFilesTree)?_c('FileBrowser',{staticClass:"w-full h-full relative z-10",class:{
                'hidden': _vm.fileExplorerHidden,
            },attrs:{"filesTree":_vm.tutorialFilesTree,"isLoadingFile":_vm.isLoadingTutorialFile,"loadIotDeviceCtrl":_vm.projectRuntime === 'pio'},on:{"open-file":_vm.onOpenFile}}):_vm._e()],1),_c('div',{staticClass:"flex flex-col flex-1 h-full relative bg-ide-gray"},[_c('div',{ref:"editorNavBar",staticClass:"flex justify-between border-b border-ide-gray bg-ide-gray",class:{
                'h-10': !_vm.consoleFullScreen,
                'h-0': _vm.consoleFullScreen,
            }},[_c('div',{staticClass:"flex flex-1"},[(_vm.activeFile.name)?_c('FileName',{staticClass:"h-full flex items-center bg-ide-gray px-4 text-gray-400 text-sm",attrs:{"filePath":_vm.activeFile.name,"fileName":_vm.activeFile.name.split('/').pop()}}):_vm._e()],1),_c('div',{staticClass:"flex items-center justify-end h-full"},[(_vm.isUpdatingTutorialFile)?_c('span',{staticClass:"text-gray-400 text-sm mr-3"},[_vm._v("Saving...")]):_vm._e(),(_vm.hasRunStatus)?_c('span',{staticClass:"text-gray-400 px-2 border-l rounded text-xs mr-3",class:{
                        'bg-blue-200 text-blue-700': _vm.runEvents.status === 'Pending',
                        'bg-green-200 text-green-700': _vm.runEvents.status === 'Running' || _vm.runEvents.status === 'Succeeded',
                        'bg-red-200 text-red-700': _vm.runEvents.status === 'Failed',
                    }},[_vm._v(_vm._s(_vm.runEvents.status))]):_vm._e()])]),_c('Editor',{staticClass:"w-full",class:{
                'flex-1': !_vm.consoleFullScreen,
                'h-0': _vm.consoleFullScreen
            },attrs:{"file":_vm.activeFile},on:{"update-file":_vm.onUpdateFile}}),_c('Console',{ref:"console",staticClass:"w-full bg-ide-gray mt-auto",attrs:{"logOutlet":_vm.logOutlet,"boardLogOutlet":_vm.boardLogOutlet,"consoleFullScreen":_vm.consoleFullScreen},on:{"size-change":_vm.onConsoleSizeChange,"toggle-console-full-screen":_vm.onToggleConsoleFullScreen}})],1),(_vm.showDeviceSyncCtrl)?_c('IotDeviceSyncCtrl'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }