import { logSocket } from './socketManager';

import {
    queue as queueResponseHandler,
} from './responseQueue';

import { APP_CONFIG } from '../..';

const LOG_EMIT_EVENTS = {
    WATCH: 'watch',
    END: 'end',
};

const generateEventId = () => `evt::log::${Date.now()}`;

export const watchSession = (params) => new Promise((resolve, reject) => {
    const {
        sessionId,
        cb,
    } = params;

    const eventId = generateEventId();
    const logEventId = generateEventId();

    const data = {
        eventId,
        logEventId,
        sessionId,
        source: APP_CONFIG.socket.log.source,
    };

    const queueFactoryFunc = () => {
        queueResponseHandler(eventId, cb);
        resolve();
    };

    logSocket
        .emit(LOG_EMIT_EVENTS.WATCH, data)
        .then(queueFactoryFunc)
        .catch(reject);
});

export const endSession = (params) => {
    const { sessionId } = params;
    const eventId = generateEventId();
    const data = {
        sessionId,
        eventId,
    };

    const responseHandler = (res) => {
        if (!(res.status >= 200 && res.status < 300)) {
            throw new Error(res.message);
        }
    };

    const queueFactoryFunc = () => queueResponseHandler(eventId, responseHandler);
    logSocket
        .emit(LOG_EMIT_EVENTS.END, data)
        .then(queueFactoryFunc)
        .catch(console.error);
};

export { watchSession as default };
