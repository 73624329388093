<template>
    <div class="p-4 flex flex-1 flex-col h-full bg-ide-file-browser-gray">
        <ContentLoader
            width="183"
            height="234"
            :speed="2"
            primaryColor="#212121"
            secondaryColor="#252525">
            <rect width="71" height="15" rx="5" />
            <rect x="20" y="29" width="103" height="15" rx="5" />
            <rect y="28" width="16" height="16" rx="4" />
            <rect x="20" y="53" width="103" height="15" rx="5" />
            <rect y="52" width="16" height="16" rx="4" />
            <rect x="20" y="78" width="103" height="15" rx="5" />
            <rect y="78" width="16" height="16" rx="4" />
            <rect x="20" y="103" width="103" height="15" rx="5" />
            <rect y="104" width="16" height="16" rx="4" />
        </ContentLoader>
    </div>
</template>

<script>
import {
    ContentLoader,
} from 'vue-content-loader';

export default {
    name: 'FileBrowserLoader',

    components: {
        ContentLoader,
    },
};
</script>
