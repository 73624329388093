<template>
    <div class="flex flex-row relative">
        <!-- IDE Explorer -->
        <div
            class="h-full flex bg-ide-file-browser-gray"
            :class="{
                'max-w-4/12 flex-1': !fileExplorerHidden,
                'w-12': fileExplorerHidden,
            }">
            <!-- Explorer Dock -->
            <div class="w-12 h-full bg-ide-dock flex flex-col relative z-20">
                <div class="w-full">
                    <button
                        @click="() => onToggleFileExplorer()"
                        class="flex items-center justify-center transition duration-200 ease-in-out outline-none focus:outline-none text-ide-dock hover:text-white py-4 w-full"
                        aria-label="Explorer"
                        data-balloon-pos="right">
                        <fileCopySvg class="h-5 fill-current" />
                    </button>
                </div>
                <div class="w-full">
                    <button
                        @click="() => !projectRunning && onRunProject()"
                        class="flex items-center justify-center transition duration-200 ease-in-out outline-none focus:outline-none text-ide-dock hover:text-white py-4 w-full"
                        :class="{
                            'cursor-wait': projectRunning,
                        }"
                        :aria-label="projectRunning ? 'Running...' : `${this.projectRunName} lesson`"
                        data-balloon-pos="right">
                        <AppLoader
                            v-if="projectRunning"
                            class="h-4 stroke-current opacity-50 " />
                        <playSvg
                            v-if="!projectRunning"
                            class="h-5 fill-current" />
                    </button>
                </div>
                <div
                    v-if="projectRunning"
                    class="w-full">
                    <button
                        @click="() => projectRunning && onStopProject()"
                        class="flex items-center justify-center transition duration-200 ease-in-out outline-none focus:outline-none text-red-600 hover:text-red-500 py-4 w-full"
                        aria-label="Stop"
                        data-balloon-pos="right">
                        <stopSvg class="h-5 fill-current" />
                    </button>
                </div>
            </div>

            <!-- Explorer Loader -->
            <FileBrowserLoader
                v-if="isLoadingTutorialFilesTree"
            />

            <!-- Explorer Main -->
            <FileBrowser
                v-if="!isLoadingTutorialFilesTree"
                :filesTree="tutorialFilesTree"
                :isLoadingFile="isLoadingTutorialFile"
                :loadIotDeviceCtrl="projectRuntime === 'pio'"
                v-on:open-file="onOpenFile"
                class="w-full h-full relative z-10"
                :class="{
                    'hidden': fileExplorerHidden,
                }"
            />
        </div>

        <!-- IDE Main -->
        <div class="flex flex-col flex-1 h-full relative bg-ide-gray">
            <!-- Editor Nav Bar -->
            <div
                ref="editorNavBar"
                class="flex justify-between border-b border-ide-gray bg-ide-gray"
                :class="{
                    'h-10': !consoleFullScreen,
                    'h-0': consoleFullScreen,
                }">
                <div class="flex flex-1">
                    <FileName
                        v-if="activeFile.name"
                        class="h-full flex items-center bg-ide-gray px-4 text-gray-400 text-sm"
                        :filePath="activeFile.name"
                        :fileName="activeFile.name.split('/').pop()"
                    />
                </div>
                <div class="flex items-center justify-end h-full">
                    <span
                        v-if="isUpdatingTutorialFile"
                        class="text-gray-400 text-sm mr-3">Saving...</span>
                    <span
                        v-if="hasRunStatus"
                        class="text-gray-400 px-2 border-l rounded text-xs mr-3"
                        :class="{
                            'bg-blue-200 text-blue-700': runEvents.status === 'Pending',
                            'bg-green-200 text-green-700': runEvents.status === 'Running' || runEvents.status === 'Succeeded',
                            'bg-red-200 text-red-700': runEvents.status === 'Failed',
                        }">{{ runEvents.status }}</span>
                </div>
            </div>

            <!-- Editor -->
            <Editor
                class="w-full"
                :class="{
                    'flex-1': !consoleFullScreen,
                    'h-0': consoleFullScreen
                }"
                :file="activeFile"
                v-on:update-file="onUpdateFile"
            />

            <!-- Console -->
            <Console
                class="w-full bg-ide-gray mt-auto"
                ref="console"
                :logOutlet="logOutlet"
                :boardLogOutlet="boardLogOutlet"
                :consoleFullScreen="consoleFullScreen"
                v-on:size-change="onConsoleSizeChange"
                v-on:toggle-console-full-screen="onToggleConsoleFullScreen"
            />
        </div>

        <!-- IOT Device Sync -->
        <IotDeviceSyncCtrl
            v-if="showDeviceSyncCtrl"
        />
    </div>
</template>

<script>
import {
    mapState,
    mapActions,
    mapMutations,
} from 'vuex';

import {
    submitCode,
    executeCode,
    stopExecution,
} from '@/utils/api/socket/session';

import {
    watchSession as watchLogSession,
    endSession as endLogSession,
} from '@/utils/api/socket/log';

import {
    watchLogs as watchDeviceLogs,
    endWatchLogs as endWatchDeviceLogs,
} from '@/utils/api/socket/iot';

import { EventBus } from '@/utils';

import { getSessionId } from '@/utils/auth';

import FileBrowserLoader from './FileBrowserLoader.vue';

const newFirmwareBuildRegex = /Firmware built, ready for download.\sURL:\s(.*)/;

export default {
    name: 'Ide',

    data() {
        return {
            isLoadingTutorialFilesTree: false,
            isLoadingTutorialFile: false,
            isUpdatingTutorialFile: false,
            tutorialFilesTree: [],
            activeFileEdits: [],
            activeFile: {},
            runEvents: {},
            logOutlet: [],
            boardLogOutlet: [],
            fileExplorerHidden: false,
            consoleFullScreen: false,
            previousPodName: null,
            currentPodName: null,
        };
    },

    computed: {
        ...mapState('tutorials', [
            'tutorial',
        ]),

        ...mapState('iotDevice', [
            'isBoardConnected',
            'showDeviceSyncCtrl',
        ]),

        ...mapState('project', [
            'projectRuntime',
            'projectRunning',
        ]),

        hasRunStatus() {
            return this.runEvents.status !== undefined;
        },

        projectRunName() {
            return this.projectRuntime === 'pio' ? 'Build' : 'Run';
        },
    },

    watch: {
        isBoardConnected(newValue) {
            if (newValue === true && !this.watchingDeviceLogs) {
                this.watchDeviceLogs(this.deviceLogListener);
            }
        },
    },

    created() {
        this.watchLogSession(this.logListener);
        this.doGetTutorialFilesTree();
    },

    beforeDestroy() {
        this.setProjectRunning(false);
        this.endWatchLogSession();
        this.endWatchDeviceLogs();
    },

    methods: {
        ...mapActions('tutorials', [
            'getTutorialFilesTree',
            'getTutorialFile',
        ]),

        ...mapMutations('project', [
            'setProjectRunning',
        ]),

        ...mapMutations('iotDevice', [
            'setNewBuildAvailable',
            'setNewBuildSrc',
        ]),

        async doGetTutorialFilesTree() {
            try {
                this.isLoadingTutorialFilesTree = true;
                const filesTree = await this.getTutorialFilesTree({
                    // eslint-disable-next-line no-underscore-dangle
                    tutorialId: this.tutorial._id,
                    runtime: this.projectRuntime,
                });
                this.tutorialFilesTree = filesTree;
                this.isLoadingTutorialFilesTree = false;
            } catch (ex) {
                this.isLoadingTutorialFilesTree = false;
                console.error(ex);
            }
        },

        async onOpenFile(fileName) {
            try {
                const localFile = this.activeFileEdits[fileName];
                if (localFile !== undefined) {
                    this.activeFile = localFile;
                }
                this.isLoadingTutorialFile = true;
                // eslint-disable-next-line no-underscore-dangle
                const tutorialId = this.tutorial._id;
                const { runtime } = this.tutorial.userData;
                const remoteFile = await this.getTutorialFile({
                    tutorialId,
                    runtime,
                    fileName,
                });
                this.activeFile = remoteFile;
                this.isLoadingTutorialFile = false;
            } catch (ex) {
                this.isLoadingTutorialFile = false;
                console.error(ex);
            }
        },

        async onUpdateFile(newContent) {
            try {
                this.isUpdatingTutorialFile = true;
                this.activeFileEdits[this.activeFile.name] = {
                    ...this.activeFile,
                    ...{ content: newContent },
                };

                await submitCode({
                    files: [{
                        name: this.activeFile.name,
                        content: newContent,
                    }],
                });
                setTimeout(() => { this.isUpdatingTutorialFile = false; }, 1000);
            } catch (ex) {
                setTimeout(() => { this.isUpdatingTutorialFile = false; }, 1000);
                console.error(ex);
            }
        },

        async submitProject() {
            const files = Object.entries(this.activeFileEdits)
                .map(([key, value]) => ({
                    name: key,
                    content: value.content,
                }));

            await submitCode({
                files,
            });
        },

        async onRunProject() {
            try {
                this.previousPodName = this.currentPodName;
                this.currentPodName = null;
                this.setProjectRunning(true);

                await this.submitProject();

                const runtime = this.tutorial.runtimes
                    .find((r) => r.name === this.tutorial.userData.runtime);
                const { entryFile, command } = runtime;
                const data = {
                    ...(command ? { command } : { entryFile }),
                };

                EventBus.$emit('clearTerminal');

                executeCode(data, this.runProjectListener);
                setTimeout(() => {
                    this.runEvents = { status: 'Pending' };
                    this.logOutlet = [];
                    this.$refs.console.openConsole('logs');
                }, 100);

                // setTimeout(() => {
                //     this.logListener({
                //         podName: 'random',
                //         message: 'Firmware built, ready for download. URL: https://assets.africastalking.com/content/09f75dcc52de72ac2798d3616937394c96a320f9/pio/1628611848/firmware.bin',
                //     });
                // }, 5000);
            } catch (ex) {
                this.runEvents = {
                    ...this.runEvents,
                    ...{ status: 'Failed' },
                };
                console.error(ex);
            }
        },

        runProjectListener(evt) {
            if (evt.data === undefined
                || !this.projectRunning) return;

            const { podName = '' } = evt.data;

            if (podName === this.previousPodName) return;

            if (!this.currentPodName && podName) this.currentPodName = podName;

            if (this.runEvents.status
                && this.runEvents.status === evt.data.status) return;

            this.runEvents = {
                ...this.runEvents,
                ...{ status: evt.data.status },
            };

            if (this.runEvents.status === 'Failed'
                || this.runEvents.status === 'Succeeded') {
                this.setProjectRunning(false);
            }
        },

        logListener(data) {
            const { podName = '' } = data;

            if (podName === this.previousPodName) return;

            if (!this.currentPodName && podName) this.currentPodName = data.podName;

            if (data.message) {
                this.logOutlet = [
                    ...this.logOutlet,
                    data.message,
                ];
            }

            if (this.projectRuntime === 'pio' && newFirmwareBuildRegex.test(data.message)) {
                const [, newFirmwareBuildSrc] = data.message.match(newFirmwareBuildRegex);
                this.setNewBuildAvailable(true);
                this.setNewBuildSrc(newFirmwareBuildSrc);
            }
        },

        deviceLogListener(data) {
            if (data.data.message) {
                this.boardLogOutlet = [
                    ...this.boardLogOutlet,
                    data.data.message,
                ];
            }
        },

        async onStopProject() {
            try {
                await stopExecution();
                this.runEvents = {};
                this.setProjectRunning(false);
            } catch (ex) {
                console.log(ex);
            }
        },

        async watchLogSession(cb) {
            try {
                const sessionId = getSessionId();
                await watchLogSession({ cb, sessionId });
            } catch (ex) {
                console.error(ex);
            }
        },

        endWatchLogSession() {
            const sessionId = getSessionId();
            if (sessionId) {
                endLogSession({ sessionId });
            }
        },

        async watchDeviceLogs(cb) {
            try {
                const sessionId = getSessionId();
                if (sessionId) {
                    await watchDeviceLogs({ cb, sessionId });
                }
            } catch (ex) {
                console.error(ex);
            }
        },

        endWatchDeviceLogs() {
            const sessionId = getSessionId();
            if (sessionId) {
                endWatchDeviceLogs({ sessionId });
            }
        },

        onToggleFileExplorer() {
            this.fileExplorerHidden = !this.fileExplorerHidden;
            setTimeout(() => {
                EventBus.$emit('resizeEditor');
                EventBus.$emit('resizeTerminal');
            }, 100);
        },

        onToggleConsoleFullScreen() {
            this.consoleFullScreen = !this.consoleFullScreen;
            setTimeout(() => {
                EventBus.$emit('resizeEditor');
                EventBus.$emit('resizeTerminal');
            }, 100);
        },

        onConsoleSizeChange() {
            setTimeout(() => {
                EventBus.$emit('resizeEditor');
            }, 100);
        },
    },

    components: {
        FileBrowserLoader,
        fileCopySvg: () => import('@/assets/img/fileCopy.svg'),
        playSvg: () => import('@/assets/img/play.svg'),
        stopSvg: () => import('@/assets/img/stop.svg'),
        AppLoader: () => import('@/components/ui/AppLoader.vue'),
        FileName: () => import('./FileName.vue'),
        Editor: () => import('@/components/tutorials/ide/Editor.vue'),
        Console: () => import('@/components/tutorials/ide/Console.vue'),
        FileBrowser: () => import('@/components/tutorials/ide/FileBrowser.vue'),
        IotDeviceSyncCtrl: () => import('@/components/tutorials/ide/IotDeviceSyncCtrl.vue'),
    },
};
</script>
